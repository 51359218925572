.example-banner-subtitle-en{
    position: absolute;
    font-size: 3.125vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #FEFEFE;
    opacity: 0.5;
    top:17.5vw;
    left: 0vw;
    z-index: 6;
    width: 100%;
    text-align: center;
}

.example-banner-subtitle-cn{
    position: absolute;
    font-size: 3.333vw;
    font-weight: 400;
    color: #FFFFFF;
    width: 100%;
    top:21.5vw;
    left: 0vw;
    z-index: 6;
    text-align: center;
}

.example-nav{
    height: 3.697vw;
    border-bottom: 1px solid #999;
}

.nav-container{
    max-width: 67.708vw;
    margin: 0 auto;
    height: 3.697vw;
    line-height: 3.697vw;
}

.nav-container .left{
    float: left;
    color: #333333;
}

.nav-container .left img{
    width: 1.041vw;
    height: 1.041vw;
}

.nav-container .left span{
    font-size: 0.9375vw;
    font-weight: 400;
    display: inline-block;
    vertical-align: 1px;
}
.nav-container .left span:nth-child(2){
    margin-left: 0.572vw;
}
.nav-container .left span:nth-child(4){
    color: #E84D82;
}

.nav-container .right{
    float: right;
    color: #333333;
}
.nav-container .right span{
    font-size: 0.9375vw;
    font-weight: 400;
    display: inline-block;
    padding-left: 2vw;
    cursor: pointer;
}
.nav-container .right .active{
    color: #E84D82;
}

.example-container {
    width: 67.708vw;
    /*padding-bottom: 4.479vw;*/
    margin: 0 auto;
    text-align: center;
}

/*.sec-title{*/
/*    margin-top: 5.208vw;*/
/*}*/
.sec-title:after{
    content: "";
    clear: both;
    visibility: hidden;
    height: 0;
    display: block;
}

.marginTop{
    margin-top: 7.239vw;
}

/*.sec-title .text{*/
/*    color: #333;*/
/*    font-size: 1.979vw;*/
/*}*/
.sec-title .line{
    margin-top: 1vw;
}

.line span{
    display: inline-block;
}
.line span:nth-child(1),.line span:nth-child(3){
    border-bottom: 1px solid #999;
    width: 7.291vw;
}

.line span:nth-child(2){
    width: 0;
    height: 0;
    border-left: 0.492vw solid transparent;
    border-right: 0.492vw solid transparent;
    border-top: 0.796vw solid #999999;
    vertical-align: -0.4vw;
    margin: 0 0.5vw;
}

.product-list{
    display: inline-block;
    width: 21.145vw;
    height: 27.437vw;
    margin-right: 3%;
    vertical-align: top;
    box-sizing: border-box;
    margin-bottom: 1.291vw;
    position: relative;
}
.product-list:nth-child(3n){
    margin-right: 0vw;
}
.product-list:nth-child(4){
    margin-bottom: 0vw;
}
.product-list:nth-child(5){
    margin-bottom: 0vw;
}
.product-list:nth-child(6){
    margin-bottom: 0vw;
}
.product-list img{
    width: 21.145vw;
    height: 23.437vw;
}
.product-list .name{
    font-size: 1.25vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: 400;
    color: #333333;
    /*margin-top: 1.354vw;*/
    width: 100%;
    height: 4vw;
    line-height: 4vw;
}
.product-list .product-list-popup{
    width: 21.145vw;
    height: 23.437vw;
    position: absolute;
    top:0;
    left: 0;
    border-radius: 0.5vw;
    display: none;
    cursor: pointer;
}
.product-list .product-list-popup .product-list-popup_bg{
    background: #000;
    box-shadow: 0px 21px 51px 0px rgba(135, 135, 135, 0.1);
    opacity: 0.5;
    width: 21.145vw;
    height: 23.437vw;
    position: absolute;
    top:0;
    left: 0;
    z-index: 9;
    border-radius: 0.5vw;
}

.product-list-popup .content{
    width: 21.145vw;
    height: 23.437vw;
    position: absolute;
    top:0;
    left: 0;
    z-index: 10;
    padding: 3vw 1vw 0;
    text-align: left;
}

.product-list-popup .title{
    font-size: 1.245vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #F9BF4E;
    margin-top: 0.5vw;
}
.product-list-popup .substr{
    font-size: 1.041vw;
    /*font-family: Microsoft YaHei;*/
    /*font-weight: bold;*/
    color: #FFFFFF;
    margin-top: 0.5vw;
}
.product-list-popup .substr li{
    margin-left: 1.3vw;
}


.product-list:nth-child(1):hover .product-list-popup{
    display: block;
}
.product-list:nth-child(2):hover .product-list-popup{
    display: block;
}
.product-list:nth-child(3):hover .product-list-popup{
    display: block;
}
.product-list:nth-child(4):hover .product-list-popup{
    display: block;
}
.product-list:nth-child(5):hover .product-list-popup{
    display: block;
}
.product-list:nth-child(6):hover .product-list-popup{
    display: block;
}

.business{
    float: left;
    width: 30.854vw;
    margin-bottom: 4.739vw;
}
.business:nth-child(2n){
    float: right;
}
.business:nth-child(5){
    margin-bottom: 0vw;
}
.business:nth-child(6){
    margin-bottom: 0vw;
}
.business img{
    float: left;
    width: 6.77vw;
    height: 6.77vw;
    /*border: 1px dotted #666;*/
}
.business .content{
    float: right;
    width: 22.833vw;
    text-align: left;
    margin-top: 0.3vw;
}
.business .content div:nth-child(1){
    font-size: 1.354vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #333333;
}
.business .content div:nth-child(2){
    font-size: 1.041vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: 400;
    color: #333333;
    margin-top: 1.041vw;
}


.cloud-container {
    width: 100%;
    margin-top: 55px;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: hidden;
}
.cloud-container .image-container {
    position: relative;
    width: 100%;
    height: 360px;
    overflow: hidden;
}
.cloud-container .image-container ul {
    display: flex;
    justify-content: space-bet;
    flex-wrap: nowrap;
    position: absolute;
    left: 0;
    top: 0;
}
.cloud-container .image-container ul li {
    list-style: none;
    width: 3672px;
    height: 360px;
}
.cloud-container .image-container ul li img {
    width: 100%;
    height: 360px;
}


@media screen and (max-width: 960px) {
    .example-banner-subtitle-en{
      font-size: 6.933vw;
      top: 27.5vw;
    }
    .example-banner-subtitle-cn{
      font-size: 5.6vw;
      top: 36.5vw;
    }
    .example-container{
       width: 93.6vw;
        padding-bottom: 9.479vw;
    }
    .product-list{
        width: 29.866vw;
        margin-right:2vw;
        height: auto;
        margin-bottom: 2vw;
    }
    .product-list:nth-child(3n){
        margin-right:0vw;
    }
    .product-list img{
        width: 100%;
    }
    .product-list .name{
        font-size: 3.2vw;
        margin-top: 2vw;
    }
    .business,.business img,.business .content{
        float: none;
    }
    .business{
        width: 100%;
        display: flex;
        justify-content:flex-start;
        margin-bottom: 3.4vw;
    }
    .business:nth-child(5){
        margin-bottom: 2.266vw;
    }
    .business img{
        width: 19.466vw !important;
        height: 17.866vw !important;
    }
    .business .content{
        margin-left: 2vw;
        width: auto;
    }
    .business .content div:nth-child(1){
        font-size: 3.733vw;
        color: #333;
    }
    .business .content div:nth-child(2){
        font-size: 3.2vw;
        color: #666;
        margin-top: 1.3vw;
    }
    .btn{
        margin-top: 6vw !important;
        margin-bottom: 0vw !important;
    }

    .product-list .product-list-popup{
        width: 0vw;
        height: 0vw;
    }
    /*.sec-text{*/
    /*    margin-top: 4.4vw;*/
    /*    padding-top:0vw !important;*/
    /*}*/
}




