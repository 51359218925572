.contactus-map{
    margin-top: 6.25vw;
}
.map-container{
    width: 19.791vw;
    height: 10.937vw;
    position: relative;
    text-align: center;
}
.map-popup{
    background: #333333;
    opacity: 0.66;
    border-radius: 0.5vw;
    position: absolute;
    top:0;
    left: 0;
    width: 19.791vw;
    height: 10.937vw;
}
.map-container .img{
    width: 7.656vw;
    height: 3.177vw;
    background: url("../images/logo2.png");
    background-size: 100% 100%;
    display: inline-block;
    position: absolute;
    top:2vw;
    left: 6vw;
}
.map-container .name{
    font-size: 1.458vw;
    /*font-family: PingFang SC;*/
    font-weight: bold;
    color: #FFFFFF;
    position: absolute;
    top:7vw;
    left: 2.5vw;
}

.contactUs-nav{
    height: 3.697vw;
    border-bottom: 1px solid #999;
}

.nav-container{
    max-width: 67.708vw;
    margin: 0 auto;
    height: 3.697vw;
    line-height: 3.697vw;
}

.nav-container .left{
    float: left;
    color: #333333;
}

.nav-container .left img{
    width: 1.041vw;
    height: 1.041vw;
}

.nav-container .left span{
    font-size: 0.9375vw;
    font-weight: 400;
    display: inline-block;
    vertical-align: 1px;
}
.nav-container .left span:nth-child(2){
    margin-left: 0.572vw;
}
.nav-container .left span:nth-child(4){
    color: #E84D82;
}

.nav-container .right{
    float: right;
    color: #333333;
}
.nav-container .right span{
    font-size: 0.9375vw;
    font-weight: 400;
    display: inline-block;
    padding-left: 2vw;
    cursor: pointer;
}
.nav-container .right .active{
    color: #E84D82;
}

.contactus-container {
    width: 67.708vw;
    padding-bottom: 4.479vw;
    margin: 0 auto;
    text-align: center;
}

.sec-title .line{
    margin-top: 1vw;
}

/*.sec-title{*/
/*    margin-top: 5.208vw;*/
/*}*/
.sec-title:after{
    content: "";
    clear: both;
    visibility: hidden;
    height: 0;
    display: block;
}

.marginTop{
    margin-top: 7.239vw;
}

/*.sec-title .text{*/
/*    color: #333;*/
/*    font-size: 1.979vw;*/
/*}*/

.line span{
    display: inline-block;
}
.line span:nth-child(1),.line span:nth-child(3){
    border-bottom: 1px solid #999;
    width: 7.291vw;
}

.line span:nth-child(2){
    width: 0;
    height: 0;
    border-left: 0.492vw solid transparent;
    border-right: 0.492vw solid transparent;
    border-top: 0.796vw solid #999999;
    vertical-align: -0.4vw;
    margin: 0 0.5vw;
}

.information-list{
    display: inline-block;
    width: 23%;
    margin-right: 2.5%;
    vertical-align: top;
}
.information-list:nth-child(4){
    margin-right: 0;
}

.information-list .bg{
    width: 4.375vw;
    height: 4.375vw;
    background: #E84D82;
    border-radius: 10vw;
    margin: 0 auto;
}
.information-list .bg img{
    width: 100%;
    height: 100%;
}
.information-list .name{
    font-size: 1.145vw;
    margin-top: 0.937vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: 400;
    color: #333333;
}
.information-list .substr{
    font-size: 0.833vw;
    margin-top: 1.354vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: 400;
    color: #666666;
}

.message-title{
    margin-top: 3.906vw;
    font-size: 1.25vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.message-contain{
    width: 38.0677vw;
    margin: 2.239vw auto 0;
    text-align: left;
}
.message-contain .name{
    font-size: 1.25vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #333333;
    opacity: 0.45;
}
.message-contain .substr{
    font-size: 1.25vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #333333;
}
.message-contain .from{
    margin-top: 2.031vw;
}
.message-contain .from:after{
    content: "";
    clear: both;
    visibility: hidden;
    height: 0;
    display: block;
}
.from .left{
    width: 16.765vw;
    float: left;
}
.from .left input{
    margin-bottom: 1.041vw;
    height: 2.515vw;
    padding-left: 1vw;
    box-sizing: border-box;
    color: #666666;
    font-size: 0.833vw;
    background: #EBEBEB;
    outline:none;
    border:none;
    border-radius:0.3vw;
    width: 100%;
}
.from .right{
   display: inline-block;
   float: right;
}
.from .right textarea{
    width: 18.645vw;
    height: 13.072vw;
    background: #EBEBEB;
    color: #666666;
    font-size: 0.833vw;
    padding: 1vw;
    box-sizing: border-box;
    outline:none;
    border:none;
    border-radius:0.3vw;
    resize: none;
}

.btn{
    color: #E84D82;
    font-size: 1.041vw;
    /*margin-top: 3.645vw;*/
    border: 1px solid #E84D82;
    border-radius: 2vw;
    padding: 0.5vw 2vw;
    cursor: pointer;
    display: inline-block;
    margin: 3.645vw auto;
}

.btn a{
    text-decoration: none;
    color: #E84D82;
}

.btn:hover{
    background: #E84D82;
}

.btn:hover a{
    color: #fff;
}

@media screen and (max-width: 960px) {
    .map_img{
        width: 100%;
    }
    .contactus-container{
        width: 93.6vw;
        padding-bottom: 9.479vw
    }
    .information-list{
        width: 30%;
    }
    .information-list .bg{
        width: 11.2vw;
        height: 11.2vw;
    }
    .information-list .bg img{
        width: 100% !important;
        height: 100% !important;
    }
    .information-list .name{
        color: #333333;
        font-size: 3.466vw;
        margin-top: 2.266vw;
    }
    .information-list .substr{
        color: #666;
        font-size: 2.933vw;
        margin-top: 2.8vw;
    }
    .message-contain{
        width: 93.6vw;
        margin-top: 4.8vw;
    }
    .message-contain .name{
        color: #333333;
        font-size: 4vw;
        font-weight: bold;
    }
    .message-contain .substr{
        font-size: 3.2vw;
        font-weight: normal;
    }
    .message-contain .from{
        margin-top: 3.066vw !important;
        font-size: 2.666vw;
    }
    .from .left{
        width: 38.826vw;
    }
    .from .left input{
        height: 5.826vw;
        margin-bottom: 2.266vw;
        background: #EBEBEB;
        opacity: 0.66;
        border-radius: 0.5vw;
        font-size: 2.4vw;
    }
    .from .right textarea{
        width: 43.24vw;
        height: 30.2vw;
        background: #EBEBEB;
        opacity: 0.66;
        border-radius: 0.5vw;
        font-size: 2.4vw;
    }
}