@media screen and (max-width: 960px) {
  .detail .title_img{
      width: 100%;
  }
    .detail-contain{
        width: 93.6vw;
        margin: 0 auto 8.933vw;
    }
    .detail-contain .name{
        margin-top: 5.466vw;
        font-size: 5.066vw;
        color: #232323;
        margin-left: -2.5vw;
    }
    .detail-contain .content{
        margin-top: 5.733vw;
    }
    .detail-contain .content .title{
        color: #232323;
        font-size: 4.266vw;
    }
    .detail-contain .content .substr{
        color: #888;
        font-size: 4vw;
        margin-top: 3.333vw;
    }
    .detail-contain .content .title:nth-child(3){
        margin-top: 6.666vw;
    }
    .detail-contain .content .substr span{
        display: inline-block;
        width: 4vw;
        height: 4vw;
        border-radius: 50%;
        border: 1.2vw solid #E84D82;
        margin-right: 2vw;
        vertical-align: -0.4vw;
    }
    .detail-contain .content .substr div:nth-child(2){
        margin-top: 4vw;
    }
}