.aboutus-banner-subtitle-en{
    position: absolute;
    font-size: 3.125vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #FEFEFE;
    opacity: 0.5;
    top:17.5vw;
    left:0vw;
    z-index: 6;
    width: 100%;
    text-align: center;
}

.aboutus-banner-subtitle-cn{
    position: absolute;
    font-size: 3.333vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: 400;
    color: #FFFFFF;
    top:21.5vw;
    left: 0vw;
    z-index: 6;
    width: 100%;
    text-align: center;
}

.aboutUs-nav{
    height: 3.697vw;
    border-bottom: 1px solid #999;
}

.nav-container{
    max-width: 67.708vw;
    margin: 0 auto;
    height: 3.697vw;
    line-height: 3.697vw;
 }

.nav-container .left{
    float: left;
    color: #333333;
}

.nav-container .left img{
   width: 1.041vw;
   height: 1.041vw;
}

.nav-container .left span{
    font-size: 0.9375vw;
    font-weight: 400;
    display: inline-block;
    vertical-align: 1px;
}
.nav-container .left span:nth-child(2){
    margin-left: 0.572vw;
}
.nav-container .left span:nth-child(4){
    color: #E84D82;
}

.nav-container .right{
    float: right;
    color: #333333;
}
.nav-container .right span{
    font-size: 0.9375vw;
    font-weight: 400;
    display: inline-block;
    padding-left: 2vw;
    cursor: pointer;
}
.nav-container .right .active{
    color: #E84D82;
}

.sec-title .line{
    margin-top: 1vw;
}

/*.sec-title{*/
/*    margin-top: 5.208vw;*/
/*}*/

/*.sec-title .text{*/
/*    color: #333;*/
/*    font-size: 1.979vw;*/
/*}*/

.line span{
    display: inline-block;
}
.line span:nth-child(1),.line span:nth-child(3){
    border-bottom: 1px solid #999;
    width: 7.291vw;
}

.line span:nth-child(2){
    width: 0;
    height: 0;
    border-left: 0.492vw solid transparent;
    border-right: 0.492vw solid transparent;
    border-top: 0.796vw solid #999999;
    vertical-align: -0.4vw;
    margin: 0 0.5vw;
}

.sec-text {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    /*padding-top: 3.958vw;*/
}

.sec-text .left {
    width: 34.427vw;
    color: #333;

}

.sec-text .right {
    width: 30.208vw;
    height: 27.552vw;
    float: right;

}

.text-title{
    font-size: 1.354vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: bold;
    color: #333333;
}
.text-paragraph p{
    text-align: left;
}

p{
    margin-top: 0.5vw;
    font-size: 1.041vw;
    text-align: center;
    line-height: 1.8vw;
}

.culture {
    justify-content: space-between;
    margin-top: 3.958vw;
}

.item-bg{
    width: 4.583vw;
    height: 3.958vw;
    margin: 0 auto;
}

.bird{
    background: url("../images/bird.png");
    background-size: 100% 100%;
}
.goal{
    background: url("../images/goal.png");
    background-size: 100% 100%;
}
.compass{
    background: url("../images/compass.png");
    background-size: 100% 100%;
}
.teach{
    background: url("../images/teach.png");
    background-size: 100% 100%;
}

.culture-item:hover{
    background: #E84D82;
}
.culture-item:hover .culture-title{
    color: #fff;
}
.culture-item:hover .culture-des p{
    color: #fff;
}
.culture-item:hover .bird{
    background: url("../images/bird1.png");
    background-size: 100% 100%;
}
.culture-item:hover .goal{
    background: url("../images/goal1.png");
    background-size: 100% 100%;
}
.culture-item:hover .compass{
    background: url("../images/compass1.png");
    background-size: 100% 100%;
}
.culture-item:hover .teach{
    background: url("../images/teach1.png");
    background-size: 100% 100%;
}

    /*.culture .active{*/

/*}*/

.culture-item {
    width:14.479vw;
    /*height: 17.708vw;*/
    /*display: flex; */
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*padding-top: 3%;*/
    padding: 1.51vw;
    border-radius: 1vw;
    /*background: red;*/
    box-shadow: 0px 14px 30px 0px rgba(132, 132, 132, 0.08);
    cursor: pointer;
}
.culture-des p{
   font-size: 0.729vw;
    color: #333;
}

/*.culture-item img{*/
/*    width: 30%;*/
/*    filter:hue-rotate(0.5)*/
/*    */

/*}*/

/*.culture-item:hover {*/
/*    background-color: #E84D82;*/

/*} */

.culture-title {
    color: #000;
    font-size: 1.145vw;
    /*font-family: Microsoft YaHei;*/
    font-weight: 400;
    margin-top: 1.25vw;
    text-align: center;
}

.culture-des {
    color: #FFF; 
    margin-top: 3.697vw;
    display: flex; 
    flex-direction: column;
    align-items: center;
    font-size: 0.729vw;
}

.team-life {
    max-width: 67.708vw;
    /*display: flex; */
    justify-content: center;
    margin: 3.958vw auto 0 ;
    
}

.aboutus-container {
    width: 67.708vw;
    padding-bottom: 15.479vw;
    margin: 0 auto;
}

.team-background{
    width: 21.875vw;
    height: 23.437vw;

}
.team_pic1{
    background: url("../images/team_pic1.png");
    background-size:95%;
    background-repeat: no-repeat;
}
.team_pic2{
    background: url("../images/team_pic2.png");
    background-size: 95%;
    background-repeat: no-repeat;
}
.team_pic3{
    background: url("../images/team_pic3.png");
    background-size: 95%;
    background-repeat: no-repeat;
}

.team_pic4{
    background: url("../images/team_pic4.png");
    background-size: 95%;
    background-repeat: no-repeat;
}
.team_pic5{
    background: url("../images/team_pic5.png");
    background-size: 95%;
    background-repeat: no-repeat;
}
.team_pic6{
    background: url("../images/team_pic6.png");
    background-size: 95%;
    background-repeat: no-repeat;
}

.slick-dots li button:before
{
    font-size: 10px;
    line-height: 10px;
}
.slick-dots li.slick-active button:before {
    color:#E84D82 !important;
}

@media screen and (max-width: 960px) {
    .aboutus-banner-subtitle-en{
        font-size: 6.933vw;
        top: 27.5vw;
    }
    .aboutus-banner-subtitle-cn{
        font-size: 5.6vw;
        top: 36.5vw;
    }
    .aboutus-container{
        width: 93.6vw;
    }
    .aboutus-container .sec-text p{
        text-indent:2em;
    }

    .culture{
        /*display: flex;*/
        /*flex-direction: column;*/
        /*flex-wrap: wrap*/
        /*align-content:flex-start;*/
        /*flex-wrap:column;*/
    }
    .culture-item{
        width: 44.266vw;
        height: 46vw;
        float: left;
        margin-top: 5.066vw;
        padding:3.133vw 2.533vw 0vw;
    }
    .culture-item:nth-child(2n){
        margin-left: 5.066vw;
    }
    .culture-item:nth-child(1),.culture-item:nth-child(2){
        margin-top: 0vw;
    }
    /*culture-item:nth-child(3){*/
    /*    margin-top: 5.066vw !important;*/
    /*}*/
    .culture:after{
        height: 0;
        display: block;
        visibility: hidden;
        content: '.';
        clear: both;
    }

    .culture-item .item-bg{
        width: 10.666vw;
        height: 9.6vw;
    }
    .culture-title{
        margin-top: 2.933vw !important;
        font-size: 3.2vw;
    }
    .aboutBtn{
        width: 1.733vw;
        height: 1.733vw;
        margin: 3.466vw auto 0;
    }
    .culture-des{
        margin-top: 1.679vw;
    }
    .culture-des p{
        line-height: 5vw;
        font-size:2.666vw;
        color: #666666;
    }
    .team-life{
        max-width: 100%;
    }
    .team-background{
        width: 21.875vw;
        height: 33.437vw;
    }
    .slick-dots li{
        margin: 0 !important;
        width: 4vw !important;
        height: 4vw!important;
    }
}