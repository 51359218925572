.container{
    max-width: 67.708vw;
    padding-bottom: 4.479vw;
    text-align: center;
}

.sec-paragraph {
    padding-top: 4vw;
    display: flex; 
    align-items: center;
    flex-direction: column;
    color: #333333;
}

/*.sec-text{*/
/*    font-size: 1.25vw;*/
/*}*/



.summary {
    margin-top: 10%;
    


}


.summary-item {
    /*width: 40.6rem;*/
    /*height: 20rem;*/
    width: 21.145vw;
    height: 23.958vw;
    background-color: #fafafa;
    -webkit-box-shadow: 0px 2.1rem 5.1rem 0px rgba(135, 135, 135, 0.16);
            box-shadow: 0px 2.1rem 5.1rem 0px rgba(135, 135, 135, 0.16);
    border-radius: 2vw;
    /*margin: 0 1rem;*/
    padding: 4.218vw 1.354vw;
}
.cont{
    font-size: 1.041vw;
    color: #333333;
    margin-top: 3.125vw;
    line-height: 2vw;
    text-align:left;
}

.summary-item-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.summary-item-title .num {
    /*font-size: 3.2rem;*/
    font-size: 3.229vw;
    color: rgba(102, 102, 102, 0.5);
    font-weight: bold;
    position: relative;
}

.summary-item-title .num::after {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0.6vw;
    /*width: 4rem;*/
    /*height: 0.6rem;*/
    width: 2.083vw;
    height: 0.3125vw;
    background-color: #f9bf4e;
}


.summary-item-title .text {
    color: #e84d82;
    /*font-size: 2.4rem;*/
    margin-left: 1vw;
    font-size: 2.8125vw;
}

.circle {
    margin-top: 4vw;
    width: 85%;
    
}

.circle-container {
    display: flex; 
    flex-direction: column;
    align-items: center;
    width: 100%;

}

.circle-item {
    width: 100%;
    position: relative;
    text-align: center;
}

.circle-item img {
    /*width: 70%;*/
    width: 10.9375vw;
    height: 10.9375vw;
}


.circle-font {
    color: #FFF;
    /*font-size: 30pt;*/
    font-weight: bold;
    font-size: 2.708vw;
}

.circle-annotation {
    margin-top: 5%;
    color: #333; 
    /*font-size: 15pt;*/
    font-size: 1.354vw;

}
.cem-list {
    margin-top: 10%;
}

.cem-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.25vw;
}

.cem-item img {
    width: 94%;
}

.sec-title img{
    width: 18.177vw;
    height: 6.822vw;
}
.title-font{
    font-size: 2.8125vw;
    font-weight: bold;
    color: #333333;
    position: absolute;
    top:5.4vw;
    left: 34.4vw;
}

.btn{
    color: #E84D82;
    font-size: 1.041vw;
    /*margin-top: 3.645vw;*/
    border: 1px solid #E84D82;
    border-radius: 2vw;
    padding: 0.5vw 2vw;
    cursor: pointer;
    display: inline-block;
    margin: 3.645vw auto;
}

.btn a{
   text-decoration: none;
    color: #E84D82;
}

.btn:hover{
    background: #E84D82;
}

.btn:hover a{
    color: #fff;
}


/* 
.partner li{
    width: 10.52vw;
    height: 3.854vw;
    display: inline-block;
    margin-right: 0.8375vw;
    margin-bottom: 0.8375vw;
}
.partner li img{
    width: 100%;
    height: 100%;
    border: 1px dashed #eee;
}
.partner li:nth-child(6n){
    margin-right:0vw;
} */

 .fontSize{
    font-size: 2.8vw !important;
 }


@media screen and (max-width: 960px) {
    .container{
        max-width:93.6vw;
        padding-bottom: 9.479vw;
    }
    .title-font{
        font-size: 4.8vw !important;
        top: 10vw !important;
        left: 48.4vw !important;
    }
    .sec-title img{
        width: 30.6vw !important;
        height: auto !important;
    }
    .sec-paragraph p{
        font-size: 3.466vw;
        margin-top:0;
        line-height:6vw;
    }
    .list-template{
        display:block;
    }
    .summary-item{
        width: 100%;
        height: auto;
        background: #F8F8F8;
        border-radius: 2vw;
        padding: 2.666vw;
        box-sizing: border-box;
        display: flex;
        box-shadow:none;
        margin-bottom: 2.533vw;
    }
    .summary-item:nth-child(3){
        margin-bottom: 0vw;
    }
    .summary-item-title .num{
        font-size: 8.266vw;
    }
    .summary-item-title .num::after{
        width: 6.083vw;
        height: 0.6vw;
    }
    .cont {
        font-size: 3.4vw;
        color: #333;
         margin-top: 0;
         line-height: 5vw;
        text-align: left;
        margin-left: 4.933vw;
    }
    .cont .text{
        color: #E84D82;
        margin-bottom: 1vw;
        font-size: 4vw;
    }
    .sec-text p{
        color: #333 !important;
        font-size: 3.466vw !important;
        line-height: 6vw !important;
    }
    .marginTop4{
        margin-top: 4vw;
        width: 50.666vw;
        height: 66.866vw;
    }

    .cem-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 3.466vw;
        width: 100%;
        padding-bottom: 1.866vw;
    }

    .cem-item img {
        width: 100%;
    }
    .cem-item div{
        color: #666666;
        font-size: 3.466vw;
        margin-top: 2.133vw;
    }
    .partner li{
        width: 29.6vw;
        height: 7.733vw;
        display: inline-block;
        margin-right: 1.733vw;
        margin-bottom: 3.2vw;
    }
    .partner li img{
        width: 100%;
        height: 100%;
        /*border: 1px dashed #eee;*/
    }
    .partner li:nth-child(3n){
        margin-right:0vw;
    }

    .btn{
        color: #E84D82;
        font-size: 1.041vw;
        /*margin-top: 3.645vw;*/
        border: 1px solid #E84D82;
        border-radius: 10vw !important;
        padding: 2vw 0vw !important;
        cursor: pointer;
        display: inline-block;
        margin: 3.645vw auto;
        background: #E84D82;
        width: 40vw;
        height: 9.333vw;
    }

    .btn a{
        text-decoration: none;
        color: #FFFFFF !important;
        font-size: 3.733vw;
    }

    .fontSize{
        font-size: 4.533vw !important;
    }

    

}


.cloud-container {
    width: 100%;
    margin-top: 55px;
    padding: 16px;
    box-sizing: border-box;
    overflow-y: hidden;
}
.cloud-container .image-container {
    position: relative;
    width: 100%;
    height: 360px;
    overflow: hidden;
}
.cloud-container .image-container ul {
    display: flex;
    justify-content: space-bet;
    flex-wrap: nowrap;
    position: absolute;
    left: 0;
    top: 0;
}
.cloud-container .image-container ul li {
    list-style: none;
    width: 3672px;
    height: 360px;
}
.cloud-container .image-container ul li img {
    width: 100%;
    height: 360px;
}
