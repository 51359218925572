
.navbar {
    background: #fff; 
    height: 6.25vw;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    font-size: 1.2rem; 
    position: sticky; 
    top: 0; 
    z-index: 999;
    
    
    
}

.navbar-container {
    display:flex; 
    justify-content: center; 
    align-items: center;
    height: 6.25vw;
    max-width: 67.708vw;
}

.navbar-logo {
    justify-self: start;
    width: 8.177vw;
    /*margin-left: 20px; */
    display: flex; 
    cursor: pointer;

}

.navbar-logo img{
    width: 8.177vw;
}


.nav-menu {
    display: grid; 
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px; 
    list-style: none; 
    text-align: center; 
    width: 60vw;
    justify-content: end;
    /*margin-right: 2rem; */

}

.nav-item {
    /*height: 2rem;*/
    height: 1.979vw;
    border-radius: 2rem;
    /* background-color: #e84d82; */
    color: #222222;
    cursor: pointer;
    /*font-size: 1.2rem;*/
    font-size: 1.145vw;
    /*width: 7rem;*/
    width: 9.291vw;
    /*margin: 0 1rem;*/
    transition: 300ms;
    -webkit-transition: 300ms;
    border-radius: 2rem;
}


.menu-icon {
    display: none;
  }

.nav-links.active {
color: #fff;
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
padding: 0.5rem 1rem;
height: 100%;
}


.nav-links {
color: #222;
display: flex;
align-items: center;
justify-content: center;
text-decoration: none;
padding: 0.5rem 1rem;
height: 100%;
}

.nav-font-style{
    border: 1px solid #5E5E5E;
    border-radius: 2rem;
    color: #5E5E5E;
}

.nav-item:hover {
    border-radius: 2rem;
    background-color: #e84d82;
    color: #fff;
    /*height: 2.5vw;*/
  }

.nav-item.active {
border-radius: 2rem;
background-color: #e84d82;
color: #fff;
}

.nav-links:hover {
    color: #fff;
    /*border-bottom: 4px solid #fff;*/
    transition: all 0.2s ease-out;
    border: none;
    /*height: 2.5vw;*/
  }


.nav-links-mobile {
    display: none;
}


.fa-bars {
    color: #000;
}

.phone-nav{
    display: none;
}

@media screen and (max-width: 960px) {
    .navbar{
        height: 11.733vw;
        justify-content:normal;
    }
    .navbar-container{
        width: 14.266vw;
        margin-left: 3.2vw;
    }
    .navbar-logo{
        width: 14.266vw;
    }
    .navbar-logo img{
        width: 14.266vw;
    }
    .NavbarItems {
        position: relative; 
    }

    .nav-menu {
        display: none;
        /*display:flex; */
        flex-direction: column; 
        width: 100%; 
        height:  90vh; 
        position: absolute; 
        left: -100%;
        transition: all 0.5s ease;
        
    }

    .nav-menu.active {
        background: #242222;
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
      }

    .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    }

    .phone-nav-list .active a{
        color: #E84D82;
        padding: 3vw 3vw 3vw 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
      }

    .fa-times {
    color: #fff;
    font-size: 2rem;
    }

    .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }

    .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
    }


    .phone-nav{
        /*display: inline-block;*/
        position: absolute;
        top:0vw;
        right: 3.2vw;
        display: flex;
        align-items: center;
    }
    .phone-nav-main{
        /*position: relative;*/
        position: absolute;
        top:2.5vw;
        right: 0;
    }
    .phone-nav-img{
        width: 6vw;
        cursor: pointer;
    }

    .phone-nav-list{
        position: fixed;
        width: 46.666vw;
        top:0vw;
        right: 0vw;
        background: #fff;
        padding: 5vw 0 ;
        height: 100%;
    }
    .phone-nav-list li{
        list-style: none;
        font-size: 4vw;
        height: 18vw;
        line-height: 18vw;
        padding: 0vw 13vw ;
        box-sizing: border-box;
    }
    .phone2{
        width: 3.733vw;
        height: 3.733vw;
        vertical-align: -0.5vw;
    }
    .popup{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        position: fixed;
        top:0;
        left: 0;
    }

    .popupBlock{
        display: block;
        z-index: 999;
    }

    .popupNone{
        display: none;
    }


}


/*.phone-nav-main{*/
/*    display: none;*/
/*}*/
/*.phone-nav-img{*/
/*    display: none;*/
/*}*/




