
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

p {
  line-height: 2;
}

a{
  text-decoration: none;
  color: #333;
}

  

@media screen and (max-width: 1300px) {
    

}

.banner {
  width: 100%;
  /*min-height: 15rem; */
  height: auto;
  background-color: #000000;
  display: flex;
}

.banner-background {
  width: 100%; 
  height: 100%;
  opacity: 0.6;
  z-index: 5;
}

.banner-title {
  color: #fff; 
  position: absolute; 
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10%;
  z-index: 6;
}

.banner-title img {
  width: 49.2vw;
}


.banner-subtitle {
  position: absolute; 
  color: #FFF; 
  font-size: 3.0rem;
  display: flex;
  width: 100%;
  margin-top: 17%;
  justify-content: center;
  z-index: 6;
}
.banner-title span{
  display: inline-block;
  width: 0.7vw;
  height: 5.671vw;
  background: #E84D82;
  margin-right: 1.281vw;
}

.container {
  max-width: 1300px;
  width: auto;
  display: flex; 
  flex-direction: column;
  margin: 0 auto; 
  align-items: center;

}


.title-font {
  font-size: 27pt;
}


.solid-line {
  border-top: 3px solid #000;
}


.sec-title {
  margin-top: 5.208vw;
  width: 100%;
  position: relative;
  text-align: center;
}


.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position:absolute;
}

.sec-text {
  display: flex; 
  align-items: center; 
  flex-direction: column;
  margin-top: 4.4vw !important;
  /*padding-top: 3%;*/
}
.sec-title .text{
  color: #333;
  font-size: 1.979vw;
}

.list-template {
  width: 100%;
  display: flex; 
  justify-content: space-evenly;
  list-style: none;
}


.displayNonePC{
  display: flex !important;
}
.displayNoneBlockPC{
  display: block !important;
}
.displayNonePhone{
  display: none !important;
}

.partner{
  list-style: none;
  margin-top: 3.1vw;
}

@media screen and (max-width: 960px) {
  .sec-title{
    margin-top: 7.2vw;
  }
  .sec-title .text{
    font-size: 4.8vw;
    font-weight: bold;
    color: #222;
  }
  .displayNonePC{
    display: none !important;
  }
  .displayNonePhone{
    display: block !important;
  }
  .displayNoneBlockPC{
    display: none !important;
  }
  .partner{
    margin-top: 4.4vw;
  }
}
