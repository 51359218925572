.footer {
    /*height: 213pt;*/
    height: 22.239vw;
    background-color: rgba(51, 51, 51, 0.91);
  }
  

.footer-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    /*max-width: 1300px;*/
    max-width: 67.708vw;
    height: 22.239vw;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 2.593vw;
    /*padding-top: 3%;*/

    
  }

.left {
    color: #ffffff;
    
}



.left .name {
/*font-size: 20pt;*/
font-size: 1.6vw;
font-weight: bold;
}

.left .Enname {
/*font-size: 12pt;*/
font-size: 1.25vw;
margin-top: 1%;
}

.left .infobox {
/*margin-top: 9%;*/
margin-top: 2.687vw;
font-size: 1.25vw;
/*font-size: 10pt;*/
}
.left .infobox p{
    text-align: left;
}



  @media screen and (max-width: 1300px) {
    .footer {
     width: 100%;
     height: auto;
   }

   .footer-container .left {
       /*text-align: center;*/
   }
 }


  .footer-container .right .tip {
    margin: 0 auto 0 auto;
    }
  

  
  



.right {
text-align: center;
}

.right .tip {
/*width: 148pt;*/
/*height: 21pt;*/
min-width: 15.416vw;
height: 2.187vw;
background-color: #e84d82;
border-radius: 2.187vw;
color: #fff;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
/*font-size: 9pt;*/
font-size: 0.937vw;
padding: 1.5vw 2vw;
}

.right .rcode {
/*width: 78pt;*/
/*height: 84.5pt;*/
width: 8.125vw;
/*height: 8.802vw;*/
margin-top: 1.5vw;
}

.right .laizi {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
font-size: 0.729vw;
color: #fff;
margin-top: 1.5vw;
-webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
}

.right .laizi .icon {
width: 9pt;
height: 10pt;
margin-right: 5pt;
}

@media screen and (max-width: 960px) {
    .footer{
        background: #F4F4F4;
        padding: 7.6vw 3.2vw 20.733vw;
        box-sizing: border-box;
    }
    .home_items{
        display: flex;
        justify-content:space-between;
    }
    .home_item_list{
        font-size: 3.2vw;
        color: #888;
    }
    .phoneBottom{
        width: 4vw;
        height: 4vw;
        vertical-align: -1vw;
        margin-right: 1vw;
    }
    .wxBottom{
        width: 3.733vw;
        height: 3.733vw;
        vertical-align: -1vw;
        margin-right: 1vw;
    }
    .foot_main{
        margin-top: 8.933vw;
        display: flex;
        justify-content:space-between;
    }
    .foot_main .infobox{}
    .foot_main .infobox div{
        font-size: 2.933vw;
        color: #777;
        line-height: 6vw;
    }
    .foot_main img{
        width: 19.2vw;
        height: 19.2vw;
    }
    .btm-btn-contain{
        width: 100%;
        margin: 0 auto;
        position: fixed;
        bottom: 5vw;
    }
    /*.btm-btn-contain a{*/
    /*    */
    /*}*/
    .btm-btn{
        background: #E84D82;
        font-size: 5vw;
        color: #fff;
        width: 67.466vw;
        height: 10.666vw;
        line-height: 10.666vw;
        border-radius: 1vw;
        margin: 7.333vw auto 0;
        text-align: center;
        margin: 0 auto;
    }

    .foot_popup{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .footer_popupBlock{
        display: block;
    }
    .footer_popupNone{
        display: none;
    }
    .phone-contain{
        width: 100%;
        position: fixed;
        bottom: 10vw;
        z-index: 1001;
    }
    .call,.cancel{
        display: block;
        width: 93.6vw;
        height: 14.666vw;
        /*background: #fff;*/
        border-radius: 2vw;
        line-height: 14.666vw;
        text-align: center;
        color: #E84D82;
        font-size: 5.333vw;
    }
    .call img{
        width: 6.4vw;
        height: 6.4vw;
        vertical-align: -1.5vw;
        margin-right: 2vw;
    }
    .call span:nth-child(2){
        display: inline-block;
        margin-right: 2vw;
    }
    .cancel{
        margin-top: 2.666vw;
    }
}